import * as React from 'react'
import { Link } from "gatsby"
import ArrowRight16 from "../../Assets/Svg-Icons/arrow-right-16.svg"
import scDev from "../../Assets/sc-dev.webp"

const Developer = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="body-content">
            <h4>Developer</h4>
            <p>
              Grassroots and advocacy professionals have unique needs, and
              Advocacy has your solutions listed below.
            </p>
            <ul>
              <li>
                <ArrowRight16 className="svg" />
                Congress
              </li>
              <li>
                <ArrowRight16 className="svg" />
                Federal Executive
              </li>
              <li>
                <ArrowRight16 className="svg" />
                State
              </li>
              <li>
                <ArrowRight16 className="svg" />
                Local
              </li>
            </ul>
            <Link
              to="/sol-developers"
              className="btn btn-outline-primary"
            >
              More Info
            </Link>
          </div>
        </div>
        <div className="col-xs-10 col-md-6 ">
          <img src={scDev} className='imgStyle' />
        </div>
      </div>
    </>
  )
}

export default Developer
